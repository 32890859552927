import { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import StyledTab from '@/components/Tab/StyledTab';
import StyledTabs from '@/components/Tab/StyledTabs';

interface IProps {
  children: React.ReactNode;
}

const APIKeyManagementLayout = ({ children }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/management/api-key/serper');
        break;
      case 1:
        navigate('/management/api-key/zalo');
        break;
      case 2:
        navigate('/management/api-key/facebook');
        break;
      case 3:
        navigate('/management/api-key/bright');
        break;
      default:
        navigate('/management/api-key/serper');
        break;
    }
  };
  const path = location.pathname;
  useEffect(() => {
    switch (path) {
      case '/management/api-key/serper':
        setValue(0);
        break;
      case '/management/api-key/zalo':
        setValue(1);
        break;
      case '/management/api-key/facebook':
        setValue(2);
        break;
      case '/management/api-key/bright':
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
  }, [path]);

  return (
    <>
      <Typography variant="h6" sx={{ fontWeight: 600, color: 'text.primary' }}>
        {t('management.apiKey.titleApiKey')}
      </Typography>

      <Box sx={{ mt: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            <StyledTab label="Serper" />
            <StyledTab label="Zalo" />
            <StyledTab label="Facebook" />
            <StyledTab label="Bright" />
          </StyledTabs>
        </Box>

        <Box sx={{ mt: 3 }}>{children}</Box>
      </Box>
    </>
  );
};
export default APIKeyManagementLayout;

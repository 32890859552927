import { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  useTheme,
  Tooltip,
  Box,
  Typography,
} from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import { formatDateAndHours } from '@/configs/date';
import useManagenent from '@/Hooks/api/useManagenent';
import { APIKeyType } from '@/types/management';

import UpdateKey from './UpdateKey';

interface EnhancedTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

interface TableKeyProps {
  listAPI: APIKeyType[];
  handleGetList: () => Promise<void>;
}

const TableKey: React.FC<TableKeyProps> = ({ listAPI, handleGetList }) => {
  const { handleDeleteAPI } = useManagenent();
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogUpdate, setOpenDialogUpdate] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogDeleteAll, setOpenDialogDeleteAll] = useState(false);
  const [selected, setSelected] = useState<readonly APIKeyType[]>([]);
  const [keyDelete, setKeyDelete] = useState<APIKeyType>();
  const [detailKey, setDetailKey] = useState<APIKeyType>();

  const deleteSuccess = () => {
    setOpenDialogDelete(false);
    setOpenDialogDeleteAll(false);
    setSelected([]);
    if (searchParams.get('page') === '1') {
      handleGetList();
    } else {
      setSearchParams({ page: '1' });
    }
  };

  const handleDeleteAll = () => {
    const listKeys = selected.map((key) => key.id);
    const params = {
      ids: listKeys,
    };
    handleDeleteAPI(params, deleteSuccess);
  };

  const handleDelete = () => {
    const params = {
      ids: [keyDelete?.id],
    };
    handleDeleteAPI(params, deleteSuccess);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow sx={{ backgroundColor: 'action.hover' }}>
          <TableCell sx={{ width: '10%' }} padding="checkbox">
            {!_.isEmpty(listAPI) && (
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            )}
          </TableCell>
          <TableCell>Id</TableCell>
          <TableCell>{t('table.apiKeyManagement.status')}</TableCell>
          <TableCell>{t('table.apiKeyManagement.createAt')}</TableCell>
          <TableCell>{t('table.apiKeyManagement.lastUpdate')}</TableCell>
          <TableCell sx={{ padding: '5px', textAlign: 'center' }}>
            {numSelected > 0 && (
              <Button
                variant="contained"
                fullWidth
                type="button"
                color="error"
                onClick={() => {
                  setOpenDialogDeleteAll(true);
                }}
                sx={{
                  width: 'fit-content',
                  minWidth: '30px',
                  textTransform: 'none',
                  fontSize: '1.2rem',
                  whiteSpace: 'nowrap',
                  padding: '4px 16px',
                }}
              >
                {t('action.delete')}
              </Button>
            )}
          </TableCell>
          <ConfirmDeleteModal
            openDialogDelete={openDialogDeleteAll}
            onOpenDialogDeleteChange={setOpenDialogDeleteAll}
            handleDelete={handleDeleteAll}
          />
        </TableRow>
      </TableHead>
    );
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(listAPI);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.findIndex((item: APIKeyType) => item.id === id);
    let newSelected: readonly APIKeyType[] = [];
    if (selectedIndex === -1) {
      const keyToAdd: APIKeyType | undefined = listAPI.find((key: APIKeyType) => key.id === id);
      if (keyToAdd) {
        newSelected = newSelected.concat(selected, keyToAdd);
      }
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.findIndex((item: APIKeyType) => item.id === id) !== -1;

  return (
    <>
      <TableContainer
        sx={{
          padding: 2,
          borderRadius: 2,
          border: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'action.hover',
          mt: 3,
        }}
      >
        <Table aria-label="simple table" sx={{ tableLayout: 'fixed' }}>
          <EnhancedTableHead
            numSelected={selected?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={listAPI?.length}
          />
          {!_.isEmpty(listAPI) ? (
            <TableBody>
              <>
                {listAPI.map((row: APIKeyType, index: number) => (
                  <TableRow key={row.id} hover tabIndex={-1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        role="checkbox"
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isSelected(row.id)}
                        inputProps={{
                          'aria-labelledby': `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>

                    <TableCell sx={{ wordBreak: 'break-word' }}>{row.id}</TableCell>
                    <TableCell>
                      {row.status === 'active' ? (
                        <Typography variant="body2" sx={{ color: 'primary.main' }}>
                          {t('management.apiKey.active')}
                        </Typography>
                      ) : (
                        <Typography variant="body2" sx={{ color: 'error.main' }}>
                          {t('management.apiKey.inactive')}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{formatDateAndHours(row.created_at)}</TableCell>
                    <TableCell>{formatDateAndHours(row.modified_at)}</TableCell>

                    <TableCell sx={{ textAlign: 'center' }}>
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip title={t('tooltip.edit')} placement="bottom">
                          <EditIcon
                            onClick={() => {
                              setDetailKey(row);
                              setOpenDialogUpdate(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              ml: { sx: 0, md: 3 },
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        </Tooltip>

                        <Tooltip title={t('tooltip.delete')} placement="bottom">
                          <DeleteIcon
                            onClick={() => {
                              setKeyDelete(row);
                              setOpenDialogDelete(true);
                            }}
                            sx={{
                              fontSize: '2rem',
                              ml: 3,
                              cursor: 'pointer',
                              '&:hover': {
                                color: 'error.main',
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                      <ConfirmDeleteModal
                        openDialogDelete={openDialogDelete}
                        onOpenDialogDeleteChange={setOpenDialogDelete}
                        handleDelete={handleDelete}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {t('notify.noData')}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <UpdateKey
        openDialogUpdate={openDialogUpdate}
        onDialogUpdateChange={setOpenDialogUpdate}
        detail={detailKey ?? ({} as APIKeyType)}
        handleGetList={handleGetList}
      />
    </>
  );
};
export default TableKey;

import { ReactElement, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Pagination } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddKey from '@/components/Management/apiKey/AddKey';
import TableKey from '@/components/Management/apiKey/TableKey';
import useManagenent from '@/Hooks/api/useManagenent';
import { APIKeyType } from '@/types/management';
import { API_FACEBOOK, PAGE_SIZE } from '@/utils/constants';

const Facebook = (): ReactElement => {
  const { t } = useTranslation();
  const { handleGetAPI } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [listAPI, setListAPI] = useState<APIKeyType[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [openDialogCreate, setOpenDialogCreate] = useState(false);

  const handleGetListKey = async () => {
    const params = {
      type: API_FACEBOOK,
      page: Number(searchParams.get('page') || 1),
      page_size: PAGE_SIZE,
    };
    const res = await handleGetAPI(params);
    const { details } = res;
    const { data, pagination } = details;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { total_items } = pagination;
    setListAPI(data);
    setTotalItems(total_items);
  };

  useEffect(() => {
    handleGetListKey();
  }, [searchParams.get('page')]);

  const handleChangePage = (pageValue: number) => {
    setSearchParams({ page: pageValue.toString() });
  };

  return (
    <>
      <Box sx={{ mt: { xs: 1, md: 3 } }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="contained"
            fullWidth
            type="button"
            onClick={() => {
              setOpenDialogCreate(true);
            }}
            sx={{
              width: '180px',
              ml: 2,
              boxShadow: 'none',
              fontWeight: 600,
              borderRadius: '5px',
              textTransform: 'none',
              backgroundColor: 'action.selected',
              color: '#02bb9f',
              '&:hover': {
                backgroundColor: 'action.hover',
              },
              whiteSpace: 'nowrap',
            }}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            {t('management.apiKey.addKey')}
          </Button>
        </Box>
        <TableKey listAPI={listAPI} handleGetList={handleGetListKey} />
        {!_.isEmpty(listAPI) && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(totalItems / PAGE_SIZE)}
              color="primary"
              onChange={(event, value) => {
                handleChangePage(value);
              }}
              page={Number(searchParams.get('page') || 1)}
            />
          </Box>
        )}
      </Box>
      <AddKey
        openDialogCreate={openDialogCreate}
        onDialogCreateChange={setOpenDialogCreate}
        type={API_FACEBOOK}
        handleGetList={handleGetListKey}
      />
    </>
  );
};
export default Facebook;

import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import Header from './Header';

interface SourcescanLayoutProps {
  children: ReactNode;
}

const SourcescanLayout: React.FC<SourcescanLayoutProps> = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Sourcescan</title>
      </Helmet>
      <Header label="Sourcescan" />
      <Box
        sx={{
          padding: '60px 20px',
          minHeight: '100vh',
          backgroundColor: 'background.dark',
        }}
      >
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Box>
    </>
  );
};

export default SourcescanLayout;

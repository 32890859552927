import { useTranslation } from 'react-i18next';

export const AppConfig = () => {
  const { t } = useTranslation();

  return {
    APP_NAME: t('identity.footer.companyName'),
    APP_SLOGAN: t('identity.footer.appSlogan'),
  };
};

export const getDescription = ({ field }: { field: string }) => {
  const registry: { [key: string]: string } = {
    phone: 'Số điện thoại liên lạc chính của người dùng.',
    email: 'Địa chỉ email chính của người dùng.',
    cccd: 'Số chứng minh nhân dân của người dùng.',
    facebook_user_id: 'ID duy nhất trên Facebook của người dùng.',
    username_twitter: 'Tên người dùng trên Twitter của người dùng.',
    username: 'Tên đăng nhập chung của người dùng.',
    name: 'Họ và tên đầy đủ của người dùng.',
    parrent_name_1: 'Tên của bố hoặc người giám hộ 1.',
    parrent_name_2: 'Tên của mẹ hoặc người giám hộ 2.',
    dob: 'Ngày tháng năm sinh của người dùng.',
    address_1: 'Địa chỉ cư trú chính của người dùng.',
    pob: 'Nơi sinh của người dùng.',
    tenDanToc: 'Dân tộc hoặc tộc người của người dùng.',
    VungMien: 'Khu vực địa lý hoặc nơi cư trú.',
    doc: 'Ngày làm căn cước.',
    address_2: 'Địa chỉ cư trú phụ của người dùng.',
    imgUrl: 'URL đến hình đại diện của người dùng.',
    sex: 'Giới tính của người dùng.',
    old_cccd: 'Số CMND cũ của người dùng.',
    pod: 'Nơi đăng ký tạm trú của người dùng.',
    bhxh: 'Số bảo hiểm xã hội của người dùng.',
    mst: 'Mã số thuế cá nhân của người dùng.',
    parrent_contact_phone: 'Số điện thoại liên lạc của bố/mẹ/người giám hộ.',
    dep: 'Phòng ban hoặc đơn vị trong tổ chức.',
    job: 'Nghề nghiệp hoặc chức vụ của người dùng.',
    idNum: 'Số định danh cá nhân của người dùng.',
    area: 'Khu vực địa lý hoặc địa điểm.',
    usage_1: 'Mục đích chính sử dụng của nguồn tài nguyên.',
    usage_2: 'Mục đích sử dụng phụ của nguồn tài nguyên.',
    limit_time_of_use: 'Thời gian giới hạn sử dụng.',
    doituongsudung: 'Đối tượng sử dụng hoặc nhóm người dùng.',
    sex_binary: 'Biểu diễn nhị phân của giới tính người dùng.',
    typecard: 'Loại hoặc hạng của thẻ.',
    // poc: 'Điểm liên lạc hoặc giao tiếp.',
    poc: 'Nơi cấp thẻ căn cước.',
    related_name: 'Tên của người liên quan.',
    related_cccd: 'Số CMND của người liên quan.',
    related_doc: 'Loại tài liệu hoặc chứng chỉ của người liên quan.',
    mavach: 'Mã vạch hoặc mã quét.',
    sophathanh: 'Số serial hoặc mã nhận dạng.',
    cancuphaply: 'Mã xác thực hoặc xác nhận.',
    ma_so_dien: 'Mã số điện.',
    makh: 'Mã khách hàng.',
    GT: 'Quốc tịch của người dùng.',
    gender: 'Giới tính của người dùng.',
    pii: 'Thông tin cá nhân có thể xác định.',
    bod: 'Bắt đầu của tài liệu.',
    related_phone: 'Số điện thoại của người liên quan.',
    related_job: 'Nghề nghiệp của người liên quan.',
    studentId: 'Số học sinh hoặc sinh viên.',
    birthdateStr: 'Ngày tháng năm sinh của người dùng dưới dạng chuỗi.',
    className: 'Tên hoặc mã lớp của người dùng.',
    emailEdu: 'Địa chỉ email giáo dục.',
    profileUrl: 'URL đến trang hồ sơ của người dùng.',
    cvUrl: 'URL đến sơ yếu lý lịch của người dùng.',
    Comment: 'Ý kiến chung hoặc ghi chú.',
    maSinhvien: 'Mã số sinh viên.',
    job_des: 'Mô tả công việc hoặc vai trò.',
    STT: 'Trạng thái hoặc vị trí trong chuỗi.',
    stk: 'Số tài khoản ngân hàng.',
    stt: 'Trạng thái hoặc tình trạng của một thực thể.',
    work_computer: 'Máy tính sử dụng cho mục đích công việc.',
    typeofVihicle: 'Loại phương tiện.',
    plate: 'Biển số đăng ký xe.',
    tax: 'Thông tin liên quan đến thuế.',
    automaker: 'Nhà sản xuất của xe.',
    AccountID: 'ID tài khoản của người dùng.',
    AccountNumber: 'Số tài khoản của người dùng.',
    etag: 'Thẻ điện tử hoặc mã nhận dạng cho việc thu phí.',
    old_cccd_issuance_date: 'Ngày cấp thẻ chứng minh thư/căn cước cũ',
    old_cccd_issuance_place: 'Nơi cấp thẻ chứng minh thư/căn cước cũ',
    cccd_issuance_date: 'Ngày cấp thẻ căn cước',
    cccd_issuance_place: 'Nơi cấp thẻ căn cước',
    tinh_trang_hon_nhan: 'Tình trạng hôn nhân',
    hoc_van: 'Tình trạng học vấn',
    parrent_job: 'Nghề nghiệp của bố/mẹ/người giám hộ.',
    employment_type: 'Loại biên chế nghề nghiệp',
    recruitment_method: 'Phương thức tuyển dụng',
    recruitment_date: 'Ngày tuyển dụng',
    decision_number: 'Số quyết định tuyển dụng',
    issuing_authority: 'Cơ quan phát hành quyết định tuyển dụng',
    truong_lop: 'Tên trường mà người dùng học',
    chuyen_nganh: 'Chuyên ngành học',
    nam_tot_nghiep: 'Năm tốt nghiệp',
    loai_tot_nghiep: 'Loại tốt nghiệp',
    lever_english: 'Trình độ tiếng anh',
    parent_cccd_1: 'Số chứng minh nhân dân hoặc căn cước công dân của bố hoặc người giám hộ 1',
    parent_old_cccd_1: 'Số CMND hoặc CCCD cũ của bố hoặc người giám hộ 1.',
    parent_cccd_2: 'Số chứng minh nhân dân hoặc căn cước công dân của mẹ hoặc người giám hộ 2.',
    parent_old_cccd_2: 'Số CMND hoặc CCCD cũ của mẹ hoặc người giám hộ 2.',
    vo_chong_name: 'Tên của vợ hoặc chồng.',
    vo_chong_old_cccd: 'Số CMND hoặc CCCD cũ của vợ hoặc chồng.',
    vo_chong_cccd: 'Số chứng minh nhân dân hoặc căn cước công dân của vợ hoặc chồng.',
    chu_ho_name: 'Tên của chủ hộ.',
    chu_ho_old_cccd: 'Số CMND hoặc CCCD cũ của chủ hộ.',
    chu_ho_cccd: 'Số chứng minh nhân dân hoặc căn cước công dân của chủ hộ.',
    ma_quan_huyen: 'Mã Quận huyện nơi ở hiện tại của người dùng.',
    ma_phuong_xa: 'Mã Phường xã nơi ở hiện tại của người dùng.',
    related_name_2: 'Tên của người liên quan.',
    related_cccd_2: 'Số CMND hoặc CCCD của người liên quan.',
    ma_so_nuoc: 'Mã số nước của người dùng.',
    bank_name: 'Tên ngân hàng.',
    ten_tk_bank: 'Tên tài khoản ngân hàng.',
    so_nhan_khau: 'Số nhân khẩu trong cùng hộ gia đình.',
    so_ho_dung_chung: 'Số hộ dùng chung 1 hoá đơn.',
    employee_code: 'Mã số nhân viên.',
    telegram_id: 'Id telegram của người dùng',
    children_name_1: 'Tên con 1 của người dùng',
    children_dob_1: 'Ngày sinh con 1 của người dùng',
    dang_vien: 'Đảng viên',
    the_dang_vien: 'Số thẻ đảng viên',
    ngay_vao_dang: 'Ngày vào đảng',
    related_address: 'Nơi ở của người liên quan',
    ton_giao: 'Loại hình tôn giáo',
    ho_khau: 'Nơi đăng ký hộ khẩu',
    nguyen_quan: 'Nguyên quán của người dùng.',
    related_user: 'Mối quan hệ của người liên quan với người dùng',
    related_que_quan: 'Quê quán của người liên quan',
    related_dob: 'Năm sinh của người liên quan',
    related_dep: 'Phòng ban hoặc đơn vị trong tổ chức của người liên quan.',
    related_tinh_trang: 'Tình trạng của người liên quan.',
    dep_address: 'Địa chỉ của cơ quan.',
    dep_mst: 'Mã số thuế của cơ quan.',
    dep_phone: 'Số điện thoại của cơ quan.',
    dep_email: 'Email của cơ quan.',
  };

  return registry[field];
};

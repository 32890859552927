import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Box, useTheme, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import AddKeywords from '@/components/Dataleak/AddKeywords';
import ConfirmDeleteModal from '@/components/Modal/ConfirmDeleteModal';
import useDataleak from '@/Hooks/api/useDataleak';
import { KeywordTypes } from '@/types/dataleak';

interface KeywordProps {
  listKeywords: KeywordTypes[];
  setListKeywords: React.Dispatch<React.SetStateAction<KeywordTypes[]>>;
  handleGetListKeywords: () => Promise<void>;
}

const Keyword: React.FC<KeywordProps> = ({ listKeywords, setListKeywords, handleGetListKeywords }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleDeleteKeywords } = useDataleak();
  const [searchParams, setSearchParams] = useSearchParams();

  const [openDialogAddKeywords, setOpenDialogAddKeywords] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [keywordSelected, setKeywordSelected] = useState('');

  const deleteSuccess = () => {
    setSearchParams({ page: String(1) });
    setOpenDialogDelete(false);
    handleGetListKeywords();
  };

  const handleDelete = async () => {
    setListKeywords((prevKeywords) => prevKeywords.filter((keyword) => keyword.id !== keywordSelected));
    await handleDeleteKeywords({ list_key_word: [keywordSelected] }, deleteSuccess);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            padding: 1,
          }}
        >
          {listKeywords.map((keyword: KeywordTypes) => (
            <Chip
              key={keyword.id}
              label={keyword.key_word}
              variant="outlined"
              onDelete={() => {
                setOpenDialogDelete(true);
                setKeywordSelected(keyword.id);
              }}
              sx={{ ml: 1, fontSize: '1.4rem' }}
            />
          ))}
        </Box>

        <Button
          variant="contained"
          fullWidth
          type="button"
          onClick={() => {
            setOpenDialogAddKeywords(true);
          }}
          sx={{
            width: 'fit-content',
            minWidth: 'fit-content',
            ml: 2,
            borderRadius: '5px',
            textTransform: 'none',
            backgroundColor: 'action.selected',
            color: '#02bb9f',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
            whiteSpace: 'nowrap',
          }}
        >
          <AddIcon sx={{ mr: 0.5 }} />
          {t('dataleak.createKeywords')}
        </Button>
      </Box>

      <AddKeywords
        openDialog={openDialogAddKeywords}
        onOpenDialogChange={setOpenDialogAddKeywords}
        handleGetListKeywords={handleGetListKeywords}
      />
      <ConfirmDeleteModal
        openDialogDelete={openDialogDelete}
        onOpenDialogDeleteChange={setOpenDialogDelete}
        handleDelete={handleDelete}
      />
    </>
  );
};
export default Keyword;

import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, Box, Toolbar, Button, Collapse, MenuItem, Stack, Link, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import WelcomeByTime from '@/components/common/format/WelcomeByTime';
import LanguageOption from '@/components/Header/Language';
import ThemeOption from '@/components/Header/Theme';
import { MenuHomeHeader } from '@/configs/MenuHomeHeader';
import useAuth from '@/Hooks/api/useAuth';
import { DARK_MODE_THEME } from '@/utils/constants';
import { decrypt } from '@/utils/crypto';
import { LOGO_ESS, LOGO_ESS_WHITE } from '@/utils/imgBase64';

import Weather from '../../components/Header/Weather';
import User from '../account/Profile';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  padding: 0,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const HeaderMobile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { logout } = useAuth();
  const serviceToken = localStorage.getItem('serviceToken');
  const inforUser = JSON.parse(decrypt(localStorage.getItem('inforUser') as string));
  const themeApp = localStorage.getItem('theme') || DARK_MODE_THEME;
  const { news, services } = MenuHomeHeader();

  const [openModalUser, setOpenModalUser] = useState(false);

  const handleCloseModalUser = () => {
    setOpenModalUser(false);
  };

  const logoutGmail = () => {
    logout();
  };

  const [open, setOpen] = useState(true);
  const [expandedServices, setExpandedServices] = useState(false);
  const [expandedNews, setExpandedNews] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleExpandServicesClick = () => {
    setExpandedServices(!expandedServices);
  };

  const handleExpandNewsClick = () => {
    setExpandedNews(!expandedNews);
  };

  const CollapseMenu = (
    <Collapse
      in={!open}
      timeout="auto"
      unmountOnExit
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          height: '100vh',
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'background.main',
          color: theme.palette.grey[600],
          boxShadow: 'none',
          borderBottom: 'none',
          paddingX: 2,
          paddingY: 1.5,
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: 0, minHeight: 'auto' }}>
          <Link href="/" rel="noopener" underline="none">
            <Box
              component="img"
              src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
              sx={{ width: '100px', display: 'flex', alignItems: 'center' }}
            />
          </Link>

          <IconButton onClick={handleClick}>
            {open ? <MenuIcon sx={{ fontSize: '2rem' }} /> : <CloseIcon sx={{ fontSize: '2rem' }} />}
          </IconButton>
        </Toolbar>
        <Stack sx={{ padding: 2 }}>
          <MenuItem
            onClick={handleExpandServicesClick}
            sx={{
              padding: 2,
              fontWeight: 600,
              color: 'text.primary',
              display: 'flex',
              flexDirection: 'row',
              borderBottom: !expandedServices ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            {t('home.label.services.services')}
            <ExpandMore expand={expandedServices} aria-expanded={expandedServices} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </MenuItem>
          <Collapse
            sx={{ ml: 2, borderBottom: `1px solid ${theme.palette.divider}` }}
            in={expandedServices}
            timeout="auto"
            unmountOnExit
          >
            {services.map((serviceItem) => (
              <MenuItem
                key={serviceItem.name}
                onClick={() => {
                  setOpen(!open);
                  window.open(serviceItem.src, '_blank');
                }}
                sx={{ padding: 2, fontWeight: 500, color: 'text.secondary', fontSize: '1.4rem' }}
              >
                {serviceItem.label}
              </MenuItem>
            ))}
          </Collapse>

          <MenuItem
            onClick={handleExpandNewsClick}
            sx={{
              padding: 2,
              fontWeight: 600,
              display: 'flex',
              flexDirection: 'row',
              color: location.pathname.includes('/news/') ? '#03C9D7' : 'text.primary',
              borderBottom: !expandedNews ? `1px solid ${theme.palette.divider}` : 'none',
            }}
          >
            {t('home.label.news.news')}
            <ExpandMore expand={expandedNews} aria-expanded={expandedNews} aria-label="show more">
              <ExpandMoreIcon />
            </ExpandMore>
          </MenuItem>
          <Collapse
            sx={{ ml: 2, borderBottom: `1px solid ${theme.palette.divider}` }}
            in={expandedNews}
            timeout="auto"
            unmountOnExit
          >
            {news.map((newsItem) => (
              <MenuItem
                key={newsItem.name}
                onClick={() => {
                  setOpen(!open);
                  if (newsItem?.src) {
                    location.pathname.includes(newsItem?.src) ? window.location.reload() : navigate(newsItem.src);
                  } else {
                    navigate('/');
                  }
                }}
                sx={{
                  padding: 2,
                  fontWeight: 500,
                  fontSize: '1.4rem',
                  color: location.pathname.includes(newsItem.src) ? 'primary.dark' : 'text.secondary',
                }}
              >
                {newsItem.label}
              </MenuItem>
            ))}
          </Collapse>
          <MenuItem
            onClick={() => {
              navigate('/cve');
              setOpen(!open);
            }}
            sx={{
              padding: 2,
              fontWeight: 600,
              color: location.pathname.includes('/cve') ? '#03C9D7' : 'text.primary',
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            {t('home.label.cve')}
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/contact');
              setOpen(!open);
            }}
            sx={{
              padding: 2,
              fontWeight: 600,
              color: location.pathname.includes('/contact') ? '#03C9D7' : 'text.primary',
            }}
          >
            {t('home.label.contact')}
          </MenuItem>
        </Stack>

        <Box sx={{ padding: 1 }}>
          <ThemeOption />
          <Box sx={{ mt: 2 }}>
            <LanguageOption />
          </Box>
        </Box>

        {serviceToken && (
          <Box sx={{ padding: 1 }}>
            <WelcomeByTime />

            <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
              <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold', color: 'text.primary' }}>
                {inforUser?.email}
                <Box
                  component="span"
                  sx={{ ml: 1, fontWeight: 'normal', textTransform: 'capitalize', fontSize: '1.2rem' }}
                >
                  {`(${inforUser?.role})`}
                </Box>
              </Typography>
            </Box>
          </Box>
        )}

        {serviceToken ? (
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Button
              variant="outlined"
              color="info"
              sx={{
                width: '30%',
                fontWeight: 500,
                textTransform: 'none',
              }}
              onClick={() => {
                setOpenModalUser(true);
              }}
            >
              {t('home.profile.profile')}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              sx={{
                fontWeight: 500,
                textTransform: 'none',
              }}
              onClick={() => {
                navigate('/management/asm');
              }}
            >
              {t('home.profile.management')}
            </Button>
            <Button
              onClick={logoutGmail}
              variant="outlined"
              color="error"
              sx={{
                boxShadow: 'none',
                fontWeight: 500,
                textTransform: 'none',
              }}
            >
              {t('action.logout')}
            </Button>
          </Toolbar>
        ) : (
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => {
                navigate('/login', { state: { from: location } });
              }}
              variant="contained"
              color="primary"
              sx={{
                width: '100%',
                fontWeight: 500,
                textTransform: 'none',
              }}
            >
              {t('action.login')}
            </Button>
          </Toolbar>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', padding: 1, marginTop: 2 }}>
          <Weather />
        </Box>
      </Box>
    </Collapse>
  );

  return (
    <>
      <Toolbar
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'space-between',
          zIndex: 11,
          paddingX: 2,
          paddingY: 1.5,
          backgroundColor: 'background.dark',
          boxShadow: '0px 7px 30px 0px rgba(90, 114, 123, 0.11)',
        }}
      >
        <Link href="/" rel="noopener" underline="none">
          <Box
            component="img"
            src={themeApp === 'dark' ? LOGO_ESS_WHITE : LOGO_ESS}
            sx={{ width: 100, display: 'flex', alignItems: 'center' }}
          />
        </Link>
        <IconButton onClick={handleClick}>
          {open ? <MenuIcon sx={{ fontSize: '2rem' }} /> : <CloseIcon sx={{ fontSize: '2rem' }} />}
        </IconButton>
      </Toolbar>
      {CollapseMenu}
      <User openModal={openModalUser} handleCloseModal={handleCloseModalUser} />
    </>
  );
};
export default HeaderMobile;

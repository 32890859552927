import React from 'react';

import { createRoot } from 'react-dom/client';
import { Helmet } from 'react-helmet';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { APP_TITLE, APP_DESCRIPTION } from './utils/constants';

require('dotenv').config();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <>
    <Helmet>
      <title>{APP_TITLE}</title>
      <meta name="description" content={APP_DESCRIPTION} />
      <meta property="og:title" content="Esecure Solution Limited Company" />
      <meta
        property="og:description"
        content="Esecure Solution Limited Company, Multi-sector technology solutions and services provider"
      />
      <meta property="og:url" content="https://asm.essvn.vn/" />
      <meta property="og:site_name" content="ESS" />
      <meta property="og:type" content="website" />
    </Helmet>
    <App />
  </>,
);

reportWebVitals();

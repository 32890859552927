import * as React from 'react';

import BadgeIcon from '@mui/icons-material/Badge';
import CakeIcon from '@mui/icons-material/Cake';
import CarRentalIcon from '@mui/icons-material/CarRental';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EastIcon from '@mui/icons-material/East';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FacebookIcon from '@mui/icons-material/Facebook';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TwitterIcon from '@mui/icons-material/Twitter';
import { CardActions, Card, CardContent, Grid, Typography, useTheme, Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import { getDescription } from '@/configs/Identity/appConfig';
import { IdentityData } from '@/interfaces/identity';

import CustomizableList from './CustomizableList';
import Photos from './Photos';

import 'react-photo-view/dist/react-photo-view.css';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton aria-expanded={expand} {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const IdentityCard = ({ identity }: { identity: IdentityData }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const items = [
    { icon: <BadgeIcon />, primaryText: identity.cccd, type: 'pii' },
    { icon: <PermIdentityIcon />, primaryText: identity?.name, type: 'fullname' },
    { icon: <CakeIcon />, primaryText: identity.dob, type: 'birthday' },
    { icon: <HomeIcon />, primaryText: identity.address_1, type: 'address' },
    { icon: <HomeIcon />, primaryText: identity.address_2, type: 'address2' },
    { icon: <EmailIcon />, primaryText: identity.email, type: 'email' },
    { icon: <PhoneAndroidIcon />, primaryText: identity.phone, type: 'phonenum' },
    { icon: <FacebookIcon />, primaryText: identity.facebook_user_id, type: 'facebook' },
    { icon: <PersonPinIcon />, primaryText: identity.username, type: 'usename' },
    { icon: <CarRentalIcon />, primaryText: identity.plate, type: 'plate' },
    { icon: <TwitterIcon />, primaryText: identity.username_twitter, type: 'twitter' },
  ];

  // const rightColumn = [
  //   { title: 'Số định danh', nodeData: identity.cccd },
  //   { title: 'Giới Tính', nodeData: identity.gender },
  //   { title: 'Dân Tộc', nodeData: identity.tenDanToc },
  //   { title: 'Ngày Tháng Năm Sinh', nodeData: identity.dob },
  //   { title: 'Nơi Đăng Ký Khai Sinh', nodeData: identity.pob },
  //   { title: 'Thường Trú', nodeData: identity.pod },
  // ]
  // const leftColumn = [
  //   { title: 'Họ Tên', nodeData: identity.name },
  //   { title: 'Tình Trạng Hôn Nhân', nodeData: identity.dep },
  //   { title: 'Quê Quán', nodeData: identity.poc },
  //   { title: 'Nơi Ở Hiện Tại', nodeData: identity.address_1 },
  // ]

  const proccessField = Object.entries(identity)
    .map(([key, value]): { field: string; title: string; value: string[] } | undefined => {
      if (
        key !== 'imgUrl' &&
        key !== 'STT' &&
        key !== 'gender' &&
        key !== 'GT' &&
        key !== 'ma_so_dien' &&
        key !== 'ma_so_nuoc' &&
        key !== 'typecard' &&
        key !== 'bod' &&
        key !== 'usage_1' &&
        key !== 'usage_2' &&
        key !== 'limit_time_of_use' &&
        key !== 'sex_binary' &&
        key !== 'error_code' &&
        !_.isEmpty(value)
      ) {
        return { field: key, title: getDescription({ field: key }), value };
      }
    })
    .filter(Boolean); // Loại bỏ các giá trị undefined
  const proccessField2 = proccessField.filter((key) => key !== undefined);
  const rightColumn = proccessField2.slice(0, Math.floor(proccessField.length / 2));
  const leftColumn = proccessField2.slice(Math.floor(proccessField.length / 2), proccessField.length);
  return (
    <Card
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'background.main',
        backdropFilter: 'blur(40px)',
        fontSize: '16px',
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: 'action.focus' }} aria-label="recipe">
            <CardMedia>
              <Avatar />
            </CardMedia>
          </Avatar>
        }
        action={
          identity?.name && (
            <IconButton
              aria-label="copy-content"
              onClick={() => {
                navigator.clipboard.writeText(identity.name[0]);
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          )
        }
        title={<Typography sx={{ fontSize: '1.2em' }}>{identity?.name ? identity?.name[0] : ''}</Typography>}
        subheader={<Typography sx={{ fontSize: '1em' }}>{identity?.cccd ? identity?.cccd[0] : ''}</Typography>}
      />

      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <PhotoProvider>
              <PhotoView
                src={
                  identity?.imgUrl
                    ? identity.imgUrl[0]
                    : 'https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000'
                }
              >
                <CardMedia
                  sx={{ borderRadius: '100rem', width: '360px', height: '360px', objectFit: 'cover' }}
                  component="img"
                  image={
                    identity?.imgUrl
                      ? identity.imgUrl[0]
                      : 'https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg?w=2000'
                  }
                  alt="Avatar"
                  className="!rounded-full"
                />
              </PhotoView>
            </PhotoProvider>

            {identity?.imgUrl && !_.isEmpty(identity?.imgUrl[1]) && (
              <>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <PhotoProvider>
                    {identity?.imgUrl.slice(1, 5).map((img: string, index: number) => (
                      <PhotoView key={img} src={img}>
                        <Grid item xs={3} sm={2} md={3}>
                          <Box component="img" src={img} sx={{ height: '80px', width: '80px', borderRadius: 1 }} />
                        </Grid>
                      </PhotoView>
                    ))}
                  </PhotoProvider>
                </Grid>
                {identity?.imgUrl.length > 5 && (
                  <Box
                    onClick={() => {
                      setOpenDialog(true);
                    }}
                    sx={{
                      mt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <Typography variant="caption">{t('management.training.seeAll')}</Typography>
                    <EastIcon sx={{ ml: 1 }} />
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <CustomizableList items={items} />
          </Grid>
        </Grid>
      </CardContent>

      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <InfoIcon sx={{ fontSize: '3rem' }} />
        </IconButton>

        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon sx={{ fontSize: '3rem' }} />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ textAlign: 'left' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {rightColumn.map((item) => (
                <React.Fragment key={Math.random()}>
                  <Typography fontSize={14} sx={{ color: 'text.secondary' }}>
                    {item?.title}
                  </Typography>
                  <Typography paragraph sx={{ ml: 1 }}>
                    {item?.value.map((itemChild) => (
                      <Typography
                        key={itemChild}
                        fontSize={16}
                        dangerouslySetInnerHTML={{ __html: itemChild.replace(/\\n/g, '<br />') }}
                      />
                    ))}
                  </Typography>
                </React.Fragment>
              ))}
            </Grid>
            <Grid item xs={12} md={6}>
              {leftColumn &&
                leftColumn.map((item) => (
                  <React.Fragment key={Math.random()}>
                    <Typography fontSize={14} sx={{ color: 'text.secondary' }}>
                      {item?.title}
                    </Typography>
                    {item?.field === 'sex' ? (
                      <Typography paragraph sx={{ ml: 1 }} fontSize={16}>
                        {(() => {
                          const value = item?.value[0]?.toLowerCase();
                          if (value === 'male') return 'Nam';
                          if (value === 'female') return 'Nữ';
                          return item?.value[0];
                        })()}
                      </Typography>
                    ) : (
                      <Typography paragraph sx={{ ml: 1 }}>
                        {item?.value &&
                          item?.value.map((itemChild) => (
                            <Typography
                              key={itemChild}
                              fontSize={16}
                              dangerouslySetInnerHTML={{ __html: itemChild.replace(/\\n/g, '<br />') }}
                            />
                          ))}
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
      <Photos openDialog={openDialog} onDialogChange={setOpenDialog} listImg={identity?.imgUrl} />
    </Card>
  );
};

import { urlApi } from '@/configs/Api';
import useRequest from '@/Hooks/common/useRequest';

const useManagementService = () => {
  const { methodGet, methodPost, methodDelete, methodPostUpload } = useRequest();

  const listNews = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const editNews = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.editNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteNews = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.deleteNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListPermissionIdentity = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listPermissionIdentity, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const permissionIdentity = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.permissionIdentity, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getList2FA = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.list2fa, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const disable2FA = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.disable2fa, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const uploadCourse = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPostUpload(urlApi.manage.uploadCourse, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const editCourse = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.editCourse, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListCourses = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.listCourses, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteCourse = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.deleteCourse, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const viewCourse = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.viewCourse, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListReport = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.reportNews, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const sendReportNews = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.reportNews, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getListSession = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.sessionTelegram, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const uploadSession = (body: {}) => {
    return new Promise((resolve, reject) => {
      methodPostUpload(urlApi.manage.uploadSession, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteSession = (id: string) => {
    return new Promise((resolve, reject) => {
      methodDelete(`${urlApi.manage.sessionTelegram}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const addAPI = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.addAPI, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const updateAPI = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.updateAPI, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const deleteAPI = (body = {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.deleteAPI, body)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const detailAPI = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodGet(urlApi.manage.detailAPI, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getAPI = (params: {}) => {
    return new Promise((resolve, reject) => {
      methodPost(urlApi.manage.getAllAPI, params)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    listNews,
    editNews,
    deleteNews,
    getListPermissionIdentity,
    permissionIdentity,
    getList2FA,
    disable2FA,
    uploadCourse,
    editCourse,
    getListCourses,
    deleteCourse,
    viewCourse,
    getListReport,
    sendReportNews,
    getListSession,
    uploadSession,
    deleteSession,
    addAPI,
    updateAPI,
    deleteAPI,
    detailAPI,
    getAPI,
  };
};

export default useManagementService;

import { useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Dialog, DialogTitle, DialogContent, Box, TextField, Button, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { Transition } from '@/components/common/Transition';
import useManagenent from '@/Hooks/api/useManagenent';

interface AddKeyProps {
  openDialogCreate: boolean;
  onDialogCreateChange: (newValue: boolean) => void;
  type: string;
  handleGetList: () => Promise<void>;
}

const AddKey: React.FC<AddKeyProps> = ({ openDialogCreate, onDialogCreateChange, type, handleGetList }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { handleAddAPI } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState('');

  const schema = yup.object().shape({
    key: yup.array().of(yup.string().required()).min(1, t('validation.fieldRequired')),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      key: [],
    },
  });

  const addSuccess = () => {
    setValue('key', []);
    onDialogCreateChange(false);
    if (searchParams.get('page') === '1') {
      handleGetList();
    } else {
      setSearchParams({ page: '1' });
    }
  };

  const onSubmit = async (data: any) => {
    const params = { type, data: data.key };
    await handleAddAPI(params, addSuccess);
  };

  useEffect(() => {
    if (openDialogCreate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogCreate]);

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    value: string[],
    onChange: (newValues: string[]) => void,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (inputValue) {
        const isValidKey = schema.isValidSync(inputValue);

        if (isValidKey) {
          onChange([...value, inputValue]);
          setInputValue('');
        }
      }
    }
  };

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogCreateChange(false);
          setValue('key', []);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.apiKey.addKey')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                }}
              >
                <Controller
                  name="key"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      options={[]}
                      freeSolo
                      inputValue={inputValue}
                      onInputChange={(event, newValue) => {
                        if (event?.type !== 'blur') {
                          setInputValue(newValue);
                        }
                      }}
                      onChange={(event, value) => {
                        field.onChange(value);
                      }}
                      sx={{
                        '.MuiChip-label': {
                          fontSize: '1.2rem',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('placeholder.apiKey')}
                          error={!!errors.key}
                          helperText={errors.key?.message}
                          onKeyDown={
                            (event: React.KeyboardEvent<HTMLInputElement>) =>
                              handleInputKeyDown(
                                event,
                                (field.value ?? []).filter((val: string): val is string => val !== undefined),
                                (newValue) => field.onChange(newValue),
                              )
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        />
                      )}
                    />
                  )}
                />
                <Typography sx={{ fontSize: '1.2rem', color: 'text.disabled', mt: 1 }}>
                  {t('smcc.enterToAdd')}
                </Typography>
              </Box>

              <Button type="submit" variant="contained" color="primary" sx={{ marginTop: 3 }}>
                {t('action.add')}
              </Button>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddKey;

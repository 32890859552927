import { useEffect, useRef, useState } from 'react';

import { Typography, Dialog, DialogTitle, DialogContent, Box, Button } from '@mui/material';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Transition } from '@/components/common/Transition';
import useManagenent from '@/Hooks/api/useManagenent';
import { API_ZALO } from '@/utils/constants';

interface AddKeyProps {
  openDialogCreate: boolean;
  onDialogCreateChange: (newValue: boolean) => void;
  handleGetList: () => Promise<void>;
}

const AddKeyZalo: React.FC<AddKeyProps> = ({ openDialogCreate, onDialogCreateChange, handleGetList }) => {
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const { handleAddAPI } = useManagenent();
  const [searchParams, setSearchParams] = useSearchParams();

  const [file, setFile] = useState<FileWithPath | null>(null);

  const addSuccess = () => {
    onDialogCreateChange(false);
    setFile(null);
    setSearchParams({ page: '1' });
  };

  const onSubmit = async () => {
    if (!file) return;
    const formData = new FormData();
    formData.append('type', API_ZALO);
    formData.append('file', file);
    await handleAddAPI(formData, addSuccess);
  };

  useEffect(() => {
    if (openDialogCreate && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [openDialogCreate]);

  const { getRootProps: getDocumentRootProps, getInputProps: getDocumentInputProps } = useDropzone({
    accept: {
      'application/json': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const fileDocument = acceptedFiles[0];
        setFile(fileDocument);
      }
    },
  });

  return (
    <>
      <Dialog
        open={openDialogCreate}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          onDialogCreateChange(false);
          setFile(null);
        }}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: { xs: '90vw', md: '60vw' },
            },
          },
          overflow: 'visible',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <DialogTitle>
          <Typography sx={{ fontSize: '2rem', marginTop: 1 }}>{t('management.apiKey.addKey')}</Typography>
        </DialogTitle>
        <DialogContent ref={contentRef}>
          <Box sx={{ backgroundColor: 'action.hover', padding: 2, borderRadius: 1 }}>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Key ( *.json )
              </Typography>
              <Box
                {...getDocumentRootProps()}
                sx={{
                  border: '2px dashed grey',
                  borderRadius: 2,
                  padding: 2,
                  textAlign: 'center',
                  marginTop: 2,
                  cursor: 'pointer',
                  backgroundColor: 'background.paper',
                }}
              >
                <input {...getDocumentInputProps()} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t('placeholder.dropzone')}
                </Typography>
                {file && (
                  <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 600 }}>
                    {file.name}
                  </Typography>
                )}
              </Box>
            </Box>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={onSubmit}
              sx={{ marginTop: 3 }}
              disabled={!file}
            >
              {t('action.add')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AddKeyZalo;
